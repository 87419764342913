@media print {
  body,
  html {
    margin: 20px;
    height: auto !important; }
  a {
    color: inherit !important;
    text-decoration: none; } }

.landing,
.configurations {
  text-align: center; }
  .landing.home-icon-links, .landing.configurations-icon-links,
  .configurations.home-icon-links,
  .configurations.configurations-icon-links {
    background-color: #f3f5f5;
    max-width: 100%;
    width: 100%;
    height: 110px;
    margin-top: 0px;
    position: relative; }
    .landing.home-icon-links.configurations-icon-links, .landing.configurations-icon-links.configurations-icon-links,
    .configurations.home-icon-links.configurations-icon-links,
    .configurations.configurations-icon-links.configurations-icon-links {
      height: 200px; }
    .landing.home-icon-links a, .landing.configurations-icon-links a,
    .configurations.home-icon-links a,
    .configurations.configurations-icon-links a {
      font-size: 18px; }
    .landing.home-icon-links .landing, .landing.configurations-icon-links .landing,
    .configurations.home-icon-links .landing,
    .configurations.configurations-icon-links .landing {
      margin: 14px 0; }
  .landing div,
  .configurations div {
    margin-top: 4.4vh;
    text-align: center;
    color: #34495e; }
  .landing span,
  .landing a,
  .configurations span,
  .configurations a {
    font-size: 28px; }
  .landing p,
  .configurations p {
    text-align: left;
    color: #666666; }
  .landing div.session,
  .configurations div.session {
    color: #34495e; }
    .landing div.session a:hover,
    .configurations div.session a:hover {
      color: #34495e; }
  .landing div.legislation,
  .configurations div.legislation {
    color: #3774ac; }
    .landing div.legislation a:hover,
    .configurations div.legislation a:hover {
      color: #3774ac; }
  .landing div.state-budget,
  .configurations div.state-budget {
    color: #57B36F; }
    .landing div.state-budget a:hover,
    .configurations div.state-budget a:hover {
      color: #57B36F; }
  .landing div.virginia-law,
  .configurations div.virginia-law {
    color: #4FC6AC; }
    .landing div.virginia-law a:hover,
    .configurations div.virginia-law a:hover {
      color: #4FC6AC; }
  .landing div.legislative-resources,
  .configurations div.legislative-resources {
    color: #EF795A; }
    .landing div.legislative-resources a:hover,
    .configurations div.legislative-resources a:hover {
      color: #EF795A; }
  .landing div.lobbyist-in-a-box,
  .configurations div.lobbyist-in-a-box {
    color: #e73c44; }
    .landing div.lobbyist-in-a-box a:hover,
    .configurations div.lobbyist-in-a-box a:hover {
      color: #e73c44; }
  .landing h1,
  .landing h3,
  .configurations h1,
  .configurations h3 {
    margin: 0;
    padding: 0; }
  .landing h1,
  .configurations h1 {
    font-size: 24px;
    font-weight: 600; }
  .landing h3,
  .configurations h3 {
    font-size: 20px; }
  .landing a,
  .configurations a {
    width: 100%;
    text-align: center;
    text-decoration: none;
    display: block;
    color: inherit; }
    .landing a.legislation, .landing a.legislation:hover,
    .configurations a.legislation,
    .configurations a.legislation:hover {
      color: #0792A0; }
    .landing a.session, .landing a.session:hover,
    .configurations a.session,
    .configurations a.session:hover {
      color: #34495e; }
  .landing .info-link,
  .configurations .info-link {
    height: 50px;
    width: 100%;
    text-align: center;
    background-color: transparent;
    background-size: 48px;
    background-repeat: no-repeat;
    background-position: center top;
    display: inline-block; }
    .landing .info-link.configurations,
    .configurations .info-link.configurations {
      height: 100px;
      background-size: 98px; }
    .landing .info-link.legislation,
    .configurations .info-link.legislation {
      background-image: url(../../images/icons/bill-info.svg); }
    .landing .info-link.session,
    .configurations .info-link.session {
      background-image: url(../../images/icons/session-info.svg); }
    .landing .info-link.members,
    .configurations .info-link.members {
      background-image: url(../../images/icons/members-information.svg); }
    .landing .info-link.committees,
    .configurations .info-link.committees {
      background-image: url(../../images/icons/committee-information.svg); }
    .landing .info-link.communications,
    .configurations .info-link.communications {
      background-image: url(../../images/icons/communications-information.svg); }
    .landing .info-link.calendar,
    .configurations .info-link.calendar {
      background-image: url(../../images/icons/calendar-information.svg); }
    .landing .info-link.minutes,
    .configurations .info-link.minutes {
      background-image: url(../../images/icons/minutes-information.svg); }
    .landing .info-link.state-budget,
    .configurations .info-link.state-budget {
      background-image: url(../../images/icons/budget-icon.svg); }
    .landing .info-link.virginia-law,
    .configurations .info-link.virginia-law {
      background-image: url(../../images/icons/law-icon.svg); }
    .landing .info-link.legislative-resources,
    .configurations .info-link.legislative-resources {
      background-image: url(../../images/icons/resource-icon.svg); }
    .landing .info-link.lobbyist-in-a-box,
    .configurations .info-link.lobbyist-in-a-box {
      background-image: url(../../images/icons/cube-icon.svg); }
    .landing .info-link.category-action,
    .configurations .info-link.category-action {
      background-image: url(../../images/icons/drawer3.svg); }

.public-list h3 {
  border-bottom: 1px solid #ccc;
  padding-bottom: 10px; }

.public-list ul {
  -webkit-column-count: 2;
  -moz-column-count: 2;
  column-count: 2; }

.public-list ul,
.public-list li {
  font-size: 13px;
  list-style: none;
  margin: 0;
  padding: 0; }

.public-list li {
  margin-bottom: 5px; }

.public-list .slidedown-list {
  position: relative;
  -webkit-column-count: 1;
  -moz-column-count: 1;
  column-count: 1;
  cursor: pointer; }
  .public-list .slidedown-list .list-links {
    background: #f0f0f0;
    border-bottom: 1px solid #ccc; }
  .public-list .slidedown-list .list-name {
    display: block;
    padding: 0 40px 0 10px; }
  .public-list .slidedown-list .list-desc {
    font-size: 14px;
    padding: 10px; }
  .public-list .slidedown-list a,
  .public-list .slidedown-list a:link {
    text-decoration: none;
    margin-right: 10px; }

.minimal-list ul {
  padding: 0; }

.minimal-list li {
  font-size: 1.4em;
  list-style-type: none; }

.minimal-list a,
.minimal-list a:hover {
  text-decoration: none;
  color: #34495e; }

.minimal-list div {
  margin-top: 0; }

.minimal-list .info-link {
  margin: 0.5em;
  height: 2em;
  width: 2em;
  background-size: 2em 2em; }

.notification-watchlist-name h2 {
  font-size: 1.3em; }

.notification-watchlist-name small {
  font-size: .65em; }

.description.notification {
  display: grid;
  grid-template-columns: auto 80px auto;
  align-items: baseline;
  justify-content: start;
  gap: 10px; }
  .description.notification .small-text {
    font-size: 15px; }
  .description.notification .docket-agenda-link-container {
    display: grid;
    gap: 2px; }
    .description.notification .docket-agenda-link-container .docket-agenda-link {
      font-size: 13px;
      text-decoration: none; }
  .description.notification h3 {
    float: right;
    width: 100%; }

.description h3 {
  width: 80%;
  border: none;
  float: left;
  margin: 1px 0 0 10px;
  padding: 0; }

.description p {
  margin: 0; }

.description input[type=checkbox] {
  float: left; }

.toggle-row {
  display: grid;
  grid-template-columns: 6fr 1fr; }
  .toggle-row a,
  .toggle-row a:link {
    margin-left: 10px;
    font-size: 17px; }
  .toggle-row.legislation-search-results {
    display: grid;
    grid-template-columns: 150px 6fr 1fr; }
    .toggle-row.legislation-search-results span {
      font-size: 15px;
      color: #000; }
    .toggle-row.legislation-search-results .icon {
      vertical-align: middle; }
    .toggle-row.legislation-search-results .bill-additional-info {
      display: block; }
      .toggle-row.legislation-search-results .bill-additional-info p {
        font-size: 12px;
        margin-left: 10px; }
      .toggle-row.legislation-search-results .bill-additional-info .margin-left {
        margin-left: 30px; }

.quick-add-container {
  display: initial; }
  .quick-add-container #bill-quick-add {
    margin-right: 5px;
    border: 1px solid #ccc;
    box-shadow: inset 0 1px 3px #ddd;
    border-radius: 5px;
    padding: 0.2em; }

.notes-and-controls {
  margin: 10px 0; }

.search-session-header {
  background-color: #e0e0e0;
  margin-bottom: 10px;
  margin-top: 10px;
  border: 1px soild #e0e0e0;
  border-radius: 4px;
  padding: 3px 0; }
  .search-session-header label {
    margin: 10px;
    font-size: 14px;
    font-weight: bold; }

.button-container {
  font-size: 13px;
  padding-right: 10px !important;
  white-space: nowrap; }
  .button-container:not(.text-align-left) {
    text-align: right !important; }

label.arrow-down-label {
  cursor: pointer;
  font-weight: bold;
  color: #34495e;
  height: 20px;
  display: inline-block;
  vertical-align: middle; }

button.arrow-down {
  cursor: pointer;
  vertical-align: middle;
  position: relative;
  width: 25px;
  height: 20px;
  min-width: 0;
  border: 0;
  padding: 0;
  text-indent: 100%;
  white-space: nowrap;
  overflow: hidden;
  background-color: transparent;
  background: url(../../images/icons/chevron-down-blue.svg) center no-repeat;
  background-size: 25px; }
  button.arrow-down.white-chevron {
    background: url(../../images/icons/chevron-down.svg) center no-repeat; }
  button.arrow-down.light-blue-chevron {
    background: url(../../images/icons/chevron-down-light-blue.svg) center no-repeat; }
  button.arrow-down.data-url {
    background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAAOsAAADrABTsv9PgAAABl0RVh0U29mdHdhcmUAd3d3Lmlua3NjYXBlLm9yZ5vuPBoAAAFASURBVFiF7ZSxTsMwFEXvC134BbKSlQxptm4sCQMIRJSlMf2SKr9CRaj4giwszJlSqUVChtH/gEQxQxopLcG1zAKSz+aXK9+b92wDFovFYvkPhHE2DWOW6utZGsbZVEd7sE8wPGM5QDmAS/fYfxO8Xqj0QZQlICoAOnU9/1Dw+tE4QPMXlG+WDgjnrue/CF4v+/Usbcwx2JRGrnciBV88/eThqAJI0ArAR6c0kEARRmy8qw2iLJHAXcccANaA86ryUHZA8Hp15PnPAK46YR0QLrrjCKIsIaL7XXMC3VTlbaHyINXHlmHMrgHMvxlITD4h303NtQMAzXx7WtyOZ6tGwLgqZw86++69BS2C18vecWyfozWBJlU5m+vuqx1gE6LvTHTNtdpuHEARwsjcKEBPCGlq/muaJ1f/ibZYLJY/yRcsmXUbNJ3YBgAAAABJRU5ErkJggg=="); }

button.arrow-up {
  cursor: pointer;
  vertical-align: middle;
  position: relative;
  width: 25px;
  height: 20px;
  min-width: 0;
  border: 0;
  padding: 0;
  text-indent: 100%;
  white-space: nowrap;
  overflow: hidden;
  background-color: transparent;
  background: url(../../images/icons/chevron-down-blue.svg) center no-repeat;
  background-size: 25px;
  -webkit-transform: rotate(-180deg);
  transform: rotate(-180deg); }
  button.arrow-up.white-chevron {
    background: url(../../images/icons/chevron-down.svg) center no-repeat; }
  button.arrow-up.light-blue-chevron {
    background: url(../../images/icons/chevron-down-light-blue.svg) center no-repeat; }
  button.arrow-up.data-url {
    background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAAOsAAADrABTsv9PgAAABl0RVh0U29mdHdhcmUAd3d3Lmlua3NjYXBlLm9yZ5vuPBoAAAFASURBVFiF7ZSxTsMwFEXvC134BbKSlQxptm4sCQMIRJSlMf2SKr9CRaj4giwszJlSqUVChtH/gEQxQxopLcG1zAKSz+aXK9+b92wDFovFYvkPhHE2DWOW6utZGsbZVEd7sE8wPGM5QDmAS/fYfxO8Xqj0QZQlICoAOnU9/1Dw+tE4QPMXlG+WDgjnrue/CF4v+/Usbcwx2JRGrnciBV88/eThqAJI0ArAR6c0kEARRmy8qw2iLJHAXcccANaA86ryUHZA8Hp15PnPAK46YR0QLrrjCKIsIaL7XXMC3VTlbaHyINXHlmHMrgHMvxlITD4h303NtQMAzXx7WtyOZ6tGwLgqZw86++69BS2C18vecWyfozWBJlU5m+vuqx1gE6LvTHTNtdpuHEARwsjcKEBPCGlq/muaJ1f/ibZYLJY/yRcsmXUbNJ3YBgAAAABJRU5ErkJggg=="); }

.slidedown-open button.arrow-down {
  -webkit-transform: rotate(-180deg);
  transform: rotate(-180deg); }

.list-links {
  display: block;
  font-size: 14px;
  padding: 10px;
  margin: 10px 0; }
  .list-links a,
  .list-links a:link {
    text-decoration: none;
    margin-right: 10px; }
  .list-links .button.bookmark,
  .list-links .button.location,
  .list-links .button.contact,
  .list-links .button.print {
    float: right; }

.public-details .parent-li {
  margin: 15px 0; }

.public-details a {
  text-decoration: none; }

.public-details h2 {
  border-bottom: 1px solid #ccc;
  padding-bottom: 10px; }

.public-details p {
  margin: 0 0 10px 0; }

.public-details p,
.public-details li {
  font-size: 14px; }

.public-details .details-header {
  display: grid;
  grid-template-columns: 3fr 1fr; }
  .public-details .details-header.bill-details-header {
    grid-template-columns: 5fr 1fr; }

.public-details .details-header-large {
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-bottom: 15px; }

.public-details .list-links,
.public-details h3,
.public-details h4 {
  margin: 0; }

.public-details .list-links {
  text-align: right; }

.public-details .details-section a,
.public-details .details-section a:link {
  display: block;
  font-size: 14px; }

.public-details .details-section ul {
  margin: 14px 0; }

.public-details .details-section ul,
.public-details .details-section li {
  padding: 0;
  list-style: none;
  font-size: inherit; }

.public-details .bill-description-editor {
  display: grid;
  grid-template-columns: 100px 1fr 15px 25px;
  grid-gap: 10px; }
  .public-details .bill-description-editor a {
    margin-top: 5px; }

.public-details .bill-summary-editor {
  display: grid;
  grid-template-columns: 1fr 15px 25px;
  grid-gap: 10px; }
  .public-details .bill-summary-editor a {
    margin-top: 5px; }
  .public-details .bill-summary-editor textarea {
    height: 125px; }

.public-details .patrons-list {
  display: grid;
  grid-template-columns: max-content max-content; }
  .public-details .patrons-list span {
    font-size: 14px; }

.public-data-files-page .csv-desc {
  padding: 10px 0px; }

.public-data-files-page .data-files-list li {
  border-bottom: solid 1px #beb9a8;
  width: 225px;
  padding: 5px 0 2px 0; }

.partner-details:last-child {
  page-break-after: auto; }

.partner-details {
  width: 900px;
  font-size: 1em;
  margin: 20px auto; }
  .partner-details .print:last-child {
    page-break-after: auto; }
  .partner-details h2 {
    margin: 0; }
  .partner-details h3 {
    font-size: 1.3em;
    padding: 15px 0px; }
  .partner-details h4 {
    margin: 0 0 10px 0; }
  .partner-details p {
    margin: 0 0 10px 0; }
  .partner-details p,
  .partner-details li {
    font-size: 0.75em; }
  .partner-details .button-bar {
    padding: 15px 0 15px 0; }
  .partner-details .details-section {
    border-top: 1px solid #c5c5c5;
    padding: 15px 0px; }
    .partner-details .details-section ul {
      margin-top: 0;
      margin-bottom: 0; }
    .partner-details .details-section a,
    .partner-details .details-section a:link {
      display: block;
      font-size: 14px; }
    .partner-details .details-section ul,
    .partner-details .details-section li {
      padding: 0;
      list-style: none;
      font-size: inherit; }

.bill-details-versions-grid-container {
  border-bottom: 1px solid #d9dddd; }
  .bill-details-versions-grid-container .bill-details-versions-grid {
    display: grid;
    grid-template-columns: 150px 1fr 210px 100px 60px 25px 25px;
    font-size: 14px;
    padding: 12px 0;
    grid-gap: 10px; }
    .bill-details-versions-grid-container .bill-details-versions-grid .double-version-links {
      display: grid;
      grid-template-columns: 230px 200px; }
    .bill-details-versions-grid-container .bill-details-versions-grid.keyword-hit-list {
      grid-template-columns: 1fr;
      grid-gap: 0px;
      border-bottom: 0;
      padding: 1px 0; }
    .bill-details-versions-grid-container .bill-details-versions-grid.not-public {
      font-style: italic; }
  .bill-details-versions-grid-container .impact-statements-grid {
    display: grid;
    grid-template-columns: 175px 2fr; }

.bill-details-table-row td:nth-child(1) {
  width: 160px; }

.bill-details-table-row td:nth-child(2) {
  width: 160px; }

.vote-search {
  padding: 5px;
  font-size: 0.8em; }
  .vote-search .vote-search-date-item,
  .vote-search .vote-search-grid {
    padding: 5px 0; }
  .vote-search hr {
    border: 0;
    border-top: 1px solid #e8e8e8;
    margin: 0; }
  .vote-search .vote-search-grid {
    grid-template-columns: 60px 1fr 25px 100px 1fr 150px; }
    .vote-search .vote-search-grid span {
      max-width: 300px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      display: inline-block; }
  .vote-search .vote-search-grid-header {
    grid-template-columns: 60px 1fr 25px 100px 1fr 150px;
    white-space: nowrap; }
  .vote-search .button-link {
    text-decoration: none; }

#search-selections-header {
  margin-block-start: 0px;
  margin-block-end: 0.5em; }

#sr-introduced {
  width: 100%; }

.homepage-links {
  font-size: 14px; }
  .homepage-links h3 {
    font-size: 19px; }
  .homepage-links a {
    text-decoration: none; }

.message-of-the-day {
  margin: 15px auto 0px auto;
  text-align: center; }
  .message-of-the-day .message-header {
    display: inline-block; }
    .message-of-the-day .message-header h3 {
      display: inline-block; }
    .message-of-the-day .message-header a {
      margin-left: 5px; }
      .message-of-the-day .message-header a.view {
        margin-bottom: 5px; }
  .message-of-the-day .date-pickers {
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-content: space-between;
    font-size: 17px;
    width: fit-content;
    margin: 0px auto 10px auto; }
    .message-of-the-day .date-pickers #motd-start-date, .message-of-the-day .date-pickers #motd-end-date {
      min-width: 150px; }
  .message-of-the-day .color-pickers {
    display: flex;
    gap: 5px;
    justify-content: center;
    margin-bottom: 10px; }
    .message-of-the-day .color-pickers .color-picker {
      cursor: pointer;
      height: 20px;
      width: 20px; }
      .message-of-the-day .color-pickers .color-picker.selected {
        border: 1px solid black; }
  .message-of-the-day #motd-new-message {
    cursor: text; }
    .message-of-the-day #motd-new-message[placeholder]:empty::before {
      content: attr(placeholder);
      color: #555; }
    .message-of-the-day #motd-new-message[placeholder]:empty:focus::before {
      content: ""; }
  .message-of-the-day .message-list {
    margin: auto;
    display: grid;
    gap: 5px; }
    .message-of-the-day .message-list .message-item {
      width: fit-content;
      min-width: 50%;
      max-width: 87.5%;
      margin: auto;
      position: relative; }
      .message-of-the-day .message-list .message-item .edit {
        position: absolute;
        top: 5px;
        right: 5px;
        z-index: 2; }

.home-search-section #find {
  font-size: 1.5em;
  color: #34495e; }

.home-search-section #search-input {
  padding: 10px;
  border: 1px solid #e0e0e0;
  border-radius: 5px 0px 0px 5px; }

.home-search-section #search-selectors {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  justify-content: space-between;
  margin-bottom: 25px;
  font-size: 15px; }
  .home-search-section #search-selectors .selector {
    display: grid;
    grid-template-columns: 1fr 2fr;
    grid-gap: 5px;
    align-items: center;
    white-space: nowrap; }
    .home-search-section #search-selectors .selector .subject {
      background-image: url("../../images/icons/book-mark.svg");
      background-repeat: no-repeat;
      background-position: 0;
      background-size: 18px; }
    .home-search-section #search-selectors .selector .member {
      background-image: url("../../images/icons/pen2.svg");
      background-repeat: no-repeat;
      background-position: 0;
      background-size: 18px; }
    .home-search-section #search-selectors .selector .introduced {
      background-image: url("../../images/icons/calendar-blue.svg");
      background-repeat: no-repeat;
      background-position: 0;
      background-size: 18px; }
    .home-search-section #search-selectors .selector label {
      margin-left: 25px;
      font-size: 15px; }
  .home-search-section #search-selectors .selector:first-of-type {
    margin: 0 15px 0 0;
    grid-template-columns: 1fr 1.8fr; }
  .home-search-section #search-selectors .selector {
    margin: 0 15px;
    grid-template-columns: 1fr 1.5fr; }
    .home-search-section #search-selectors .selector input {
      box-shadow: inherit; }
  .home-search-section #search-selectors .selector:last-of-type {
    margin: 0 0 0 15px;
    grid-template-columns: 1fr 1fr; }
  .home-search-section #search-selectors .search-option-label {
    color: #34495e; }
  .home-search-section #search-selectors #bills-by-date {
    width: 100%; }

.selection-bubble {
  background-color: #eaf5fc;
  color: #3498db;
  border-radius: 10px;
  padding: 5px 10px;
  font-size: 0.8em !important;
  align-items: center;
  display: inline-block;
  margin: 5px 10px; }
  .selection-bubble.button {
    padding: 0px; }
    .selection-bubble.button button {
      border-radius: 10px;
      padding: 5px 15px; }
  .selection-bubble span {
    padding: 0px 5px;
    line-height: normal;
    font-weight: 700; }

.statistics-table {
  margin: 0 auto;
  border-collapse: collapse; }
  .statistics-table h1 {
    margin-bottom: 0px; }
  .statistics-table caption {
    text-align: left;
    padding-bottom: 20px; }
  .statistics-table th,
  .statistics-table td {
    border: 1px solid black;
    text-align: center;
    padding: 5px; }
  .statistics-table td:first-child {
    text-align: left; }
  .statistics-table tr:nth-child(even) td {
    background-color: #f1f1f1; }
  .statistics-table a {
    text-decoration: none; }

.committee-member-details {
  white-space: pre-wrap; }

.footer-wrapper {
  margin-top: 37.2vh;
  padding: 0 25px;
  background-color: #E3E4E4;
  width: 100%; }
  .footer-wrapper.home {
    margin-top: 0; }

.footer {
  width: 100%;
  height: 110px;
  text-align: center;
  padding-top: 25px;
  background: url(../../images/ga_seal_lis.png) right no-repeat #E3E4E4;
  background-position-y: 1vh; }
  .footer div:first-of-type {
    display: inline-flex; }
    .footer div:first-of-type a {
      margin: 0px 5px; }
  .footer span {
    font-size: 14px; }
  .footer a {
    font-size: 14px; }
    .footer a:not(:hover) {
      color: #333; }
  .footer img {
    float: right; }

.watchlist-login-wrapper {
  height: 30px;
  padding-left: 10px; }
  .watchlist-login-wrapper a {
    vertical-align: middle;
    text-decoration: none; }

@media print {
  * {
    font-size: 14px !important; }
  .inner-grid {
    display: block !important; }
    .inner-grid.version-print-preview {
      display: grid !important;
      /* Have to fight setting every font-size at 14px */ }
      .inner-grid.version-print-preview .status-text .vertical-text-lg {
        font-size: 48px !important; }
      .inner-grid.version-print-preview .status-text .vertical-text-sm {
        font-size: 17px !important; } }

.version-print-preview p:empty {
  display: none; }

.public-chamber-data .chamber-name {
  position: relative; }

.public-chamber-data .chamber-name::after {
  content: '';
  width: 45px;
  height: 32px;
  position: absolute;
  right: 0px;
  background-image: url(../../images/icons/chevron-down-blue.svg);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  transform: rotate(0deg); }

.public-chamber-data .chamber-name.opened::after {
  transform: rotate(180deg); }

.meetings-page-toggle {
  margin: 0px 5px; }
  .meetings-page-toggle[aria-current]:not([aria-current="false"]) {
    background-color: #196090; }

@keyframes expand {
  0% {
    background-color: transparent; }
  100% {
    background-color: blue; } }

.button-icon-text-container {
  position: relative;
  display: inline-grid;
  padding: 3px 5px; }
  .button-icon-text-container.slide {
    -webkit-transition: width 0.5s, background-color 0.5s 1.45s;
    transition: width 0.5s, background-color 0.5s 1.45s;
    background-color: transparent; }
    .button-icon-text-container.slide .button-text {
      max-width: 0;
      display: inline-block;
      -webkit-transition: color .25s 1.5s, max-width 2s;
      transition: color .25s 1.5s, max-width 2s;
      vertical-align: top;
      white-space: pre;
      overflow: hidden; }
    .button-icon-text-container.slide:hover {
      transition: background-color 0.5s;
      background-color: #3498db; }
      .button-icon-text-container.slide:hover .button-text {
        max-width: 300px; }
  .button-icon-text-container:not(.slide) {
    gap: 5px; }
    .button-icon-text-container:not(.slide) .button-text a {
      color: inherit; }
    .button-icon-text-container:not(.slide).change-success {
      animation: success 2s linear; }

@keyframes success {
  25% {
    color: #FFFFFF !important; }
  50% {
    background-color: #5cb85c;
    color: #FFFFFF !important; }
  75% {
    color: #FFFFFF !important; } }
  .button-icon-text-container .icon {
    cursor: initial;
    vertical-align: bottom; }
  .button-icon-text-container input[type=file],
  .button-icon-text-container input[type=file]::-webkit-file-upload-button {
    /* chromes and blink button */
    cursor: pointer; }

.help-banner {
  background-color: #ececec;
  border-color: #d9e0da;
  color: #444;
  padding: 9px;
  margin-bottom: 10px;
  text-align: center;
  font-size: 15px;
  font-family: 'Lato', helvetica, arial, sans-serif;
  border: 1px solid transparent; }
  .help-banner .close {
    cursor: pointer;
    border: none;
    margin: -5px 0 0;
    padding: 0px;
    float: right;
    font-size: 26px;
    font-weight: 500;
    line-height: 1;
    opacity: 0.5; }
  .help-banner a {
    text-decoration: none; }

.alert {
  position: relative;
  padding: .75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: .25rem;
  text-align: center; }
  .alert.mb-0 {
    margin-bottom: 0px; }
  .alert p {
    margin: 0px; }

.alert-warning {
  color: #856404;
  background-color: #fff3cd;
  border-color: #ffeeba; }

.alert-danger {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb; }

.alert-success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb; }

.alert-info {
  color: #0c5460;
  background-color: #d1ecf1;
  border-color: #bee5eb; }

.alert-primary {
  color: #004085;
  background-color: #cce5ff;
  border-color: #b8daff; }

.alert-default {
  border: 1px dashed black; }
