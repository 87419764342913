.member-contact-container {
  border-right: 1px solid #e4e4e4;
  border-left: 1px solid #e4e4e4;
  padding: 10px; }
  .member-contact-container .member-contact-type {
    font-size: 1.2em; }
  .member-contact-container .member-contact-title {
    font-weight: 600; }

.user-forms {
  background: #f1f1f1;
  border-radius: 4px;
  border: 1px solid #d8d8d8;
  padding: 20px;
  margin-top: 0; }
  .user-forms .motion-form {
    margin-bottom: 10px; }

.align-right {
  text-align: right; }

.button-bar {
  display: grid;
  grid-template-columns: 4fr 2fr;
  padding: 15px 0 0 0;
  margin-top: 20px;
  border-top: 1px solid #d8d8d8; }
  .button-bar button, .button-bar .button {
    margin-left: 10px; }

.ReactTable .rt-thead .rt-th, .ReactTable .rt-thead .rt-td {
  background: #34495e;
  color: #ffffff;
  font-size: 14px;
  border-right: 1px solid #fff;
  padding: 8px 5px; }

.ReactTable .rt-tbody .rt-td {
  border-right: 1px solid #dadada;
  border-bottom: 1px solid #dadada; }
