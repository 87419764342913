.tox-sidebar-wrap {
	overflow: auto;
}

.tox-tiered-menu {
    div {
        z-index: 1103 !important;
    }
  }

#tinymce {
    td {
        border: 1px solid #000;
    }
}

.mce-match-marker {
    background-color: #ffff00;

    &.mce-match-marker-selected {
        background-color: #ff9632;
    }
}