 /* Font Definitions */
 @font-face
	{font-family:Courier;
	panose-1:2 7 4 9 2 2 5 2 4 4;}
@font-face
	{font-family:Times;
	panose-1:2 2 6 3 5 4 5 2 3 4;}
 /* Style Definitions */
 p.MsoNormal, li.MsoNormal, div.MsoNormal
	{margin:0in;
	margin-bottom:.0001pt;
	line-height:24.0pt;
	text-autospace:none;
	font-size:12.0pt;
	font-family:Times New Roman;}
span.MsoLineNumber
	{font-weight:bold;}
p.textind, li.textind, div.textind
	{margin:0in;
	margin-bottom:.0001pt;
	text-align:justify;
	text-indent:.5in;
	line-height:24.0pt;
	font-size:12.0pt;
	font-family:"Times New Roman";}
p.enactstm, li.enactstm, div.enactstm
	{margin:0in;
	margin-bottom:.0001pt;
	text-align:justify;
	text-indent:.5in;
	line-height:24.0pt;
	font-size:12.0pt;
	font-family:"Times New Roman";
	font-weight:bold;

	.bracket {
		font-style: normal;
		font-weight: normal;
		text-decoration: none;
	}
}
p.textbl, li.textbl, div.textbl
	{margin:0in;
	margin-bottom:.0001pt;
	text-align:justify;
	line-height:24.0pt;
	font-size:12.0pt;
	font-family:"Times New Roman";}
p.textbi, li.textbi, div.textbi
	{margin-top:0in;
	margin-right:0in;
	margin-bottom:0in;
	margin-left:.5in;
	margin-bottom:.0001pt;
	text-align:justify;
	line-height:24.0pt;
	font-size:12.0pt;
	font-family:"Times New Roman";}
p.textasis, li.textasis, div.textasis
	{margin:0in;
	margin-bottom:.0001pt;
	font-size:11.0pt;
	font-family:Courier;
	color:black;}
p.table, li.table, div.table
	{margin:0in;
	margin-bottom:.0001pt;
	font-size:11.0pt;
	font-family:Courier;
	color:black;}
p.testc, li.testc, div.testc
	{margin:0in;
	margin-bottom:.0001pt;
	text-align:center;
	line-height:24.0pt;
	font-size:12.0pt;
	font-family:Courier;}
p.tablec, li.tablec, div.tablec
	{margin:0in;
	margin-bottom:.0001pt;
	text-align:center;
	line-height:24.0pt;
	font-size:12.0pt;
	font-family:Courier;}
p.tblhdn, li.tblhdn, div.tblhdn
	{margin:0in;
	margin-bottom:.0001pt;
	valign:bottom;
	text-align:left;
	margin-right:3.0pt;
	line-height:24.0pt;
	font-size:12.0pt;
	font-family:"Times New Roman";}
p.tblbi1, li.tblbi1, div.tblbi1
	{margin:0in;
	margin-left:24px;
	margin-bottom:.0001pt;
	text-align:left;
	line-height:24.0pt;
	font-size:12.0pt;
	font-family:"Times New Roman";}
p.tblbi2, li.tblbi2, div.tblbi2
	{margin:0in;
	margin-bottom:.0001pt;
	margin-left:48px;
	text-align:left;
	line-height:24.0pt;
	font-size:12.0pt;
	font-family:"Times New Roman";}
p.tbllft, li.tbllft, div.tbllft
	{margin:0in;
	margin-bottom:.0001pt;
	text-align:left;
	line-height:24.0pt;
	font-size:12.0pt;
	font-family:"Times New Roman";}
p.tblctr, li.tblctr, div.tblctr
	{margin:0in;
	margin-bottom:.0001pt;
	text-align:center;
	line-height:24.0pt;
	font-size:12.0pt;
	font-family:"Times New Roman";}
p.tblrt, li.tblctr, div.tblctr
	{margin:0in;
	margin-bottom:.0001pt;
	text-align:right;
	line-height:24.0pt;
	font-size:12.0pt;
	font-family:"Times New Roman";}
p.textctr, li.textctr, div.textctr
	{margin:0in;
	margin-bottom:.0001pt;
	text-align:center;
	line-height:24.0pt;
	font-size:12.0pt;
	font-family:"Times New Roman";}
p.patronLine, li.patronLine, div.patronLine {
	margin: 0in;
	margin-bottom: .0001pt;
	text-align: center;
	line-height: 24.0pt;
	font-size: 12.0pt;
	font-family: "Times New Roman";
}
p.center, li.center, div.center
	{margin:0in;
	margin-bottom:.0001pt;
	text-align:center;
	line-height:24.0pt;
	font-size:12.0pt;
	font-family:"Times New Roman";}
p.drafter, li.drafter, div.drafter
	{margin:0in;
	margin-bottom:.0001pt;
	line-height:24.0pt;
	font-size:12.0pt;
	font-family:"Times New Roman";
	font-weight:bold;}
p.bilblank, li.bilblank, div.bilblank
	{margin-top:12.0pt;
	margin-right:0in;
	margin-bottom:0in;
	margin-left:0in;
	margin-bottom:.0001pt;
	text-align:center;
	line-height:24.0pt;
	font-size:12.0pt;
	font-family:"Times New Roman";
	font-weight:bold;}
p.enactcls, li.enactcls, div.enactcls
	{margin:0in;
	margin-bottom:.0001pt;
	text-align:justify;
	line-height:24.0pt;
	font-size:12.0pt;
	font-family:"Times New Roman";
	font-weight:bold;
	.bracket {
		font-style: normal;
		font-weight: normal;
		text-decoration: none;
	}}
p.pro, li.pro, div.pro
	{margin:0in;
	margin-bottom:.0001pt;
	text-align:center;
	line-height:24.0pt;
	font-size:12.0pt;
	font-family:"Times New Roman";}
p.section, li.section, div.section
	{margin:0in;
	margin-bottom:.0001pt;
	text-align:justify;
	text-indent:.5in;
	line-height:24.0pt;
	font-size:12.0pt;
	font-family:"Times New Roman";
	color:black;}
p.catchln, li.catchln, div.catchln
	{margin:0in;
	margin-bottom:.0001pt;
	text-align:justify;
	text-indent:.5in;
	line-height:24.0pt;
	font-size:12.0pt;
	font-family:"Times New Roman";
	color:black;
	font-weight:bold;
	.bracket {
		font-style: normal;
		font-weight: normal;
		text-decoration: none;
	}
}
p.sumtext:not(div.bill-details-summary p.sumtext), li.sumtext, div.sumtext
	{margin:0in;
	margin-bottom:.0001pt;
	text-align:justify;
	text-indent:.5in;
	text-autospace:none;
	font-size:12.0pt;
	font-family:"Times New Roman";}
p.lddata, li.lddata, div.lddata
	{margin:0in;
	margin-bottom:.0001pt;
	font-size:10.0pt;
	font-family:"Times New Roman";}
p.end, li.end, div.end
	{margin:0in;
	margin-bottom:.0001pt;
	text-align:center;
	line-height:24.0pt;
	font-size:12.0pt;
	font-family:"Times New Roman";}
p.ld, li.ld, div.ld
	{margin:0in;
	margin-bottom:.0001pt;
	line-height:24.0pt;
	font-size:12.0pt;
	font-family:"Times New Roman";}
p.patctr, li.patctr, div.patctr
	{margin:0in;
	margin-bottom:.0001pt;
	text-align:center;
	line-height:24.0pt;
	font-size:12.0pt;
	font-family:"Times New Roman";}
p.Form, li.Form, div.Form
	{margin:0in;
	margin-bottom:.0001pt;
	font-size:11.0pt;
	font-family:Courier;
	color:black;}
p.ldtitle, span.ldtitle, li.ldtitle, div.ldtitle
	{margin-top:0in;
	margin-right:0in;
	margin-bottom:12.0pt;
	margin-left:.5in;
	text-align:justify;
	text-indent:-.5in;
	line-height:24.0pt;
	font-size:12.0pt;
	font-family:"Times New Roman";}
span.ldtitle 
	{margin-left: .25in;}
p.patalt, li.patalt, div.patalt
	{margin-top:12.0pt;
	margin-right:0in;
	margin-bottom:12.0pt;
	margin-left:.5in;
	text-align:justify;
	text-indent:-.5in;
	line-height:24.0pt;
	font-size:12.0pt;
	font-family:"Times New Roman";
	font-weight:bold;}
p.textrt, li.textrt, div.textrt
	{margin:0in;
	margin-bottom:.0001pt;
	text-align:right;
	text-indent:.5in;
	line-height:24.0pt;
	font-size:12.0pt;
	font-family:"Times New Roman";
	color:black;}
p.texthdrchar
	{margin-top:12.0pt;
	margin-right:0in;
	margin-bottom:6.0pt;
	margin-left:0in;
	text-align:center;
	line-height:24.0pt;
	text-autospace:none;
	font-size:12.0pt;
	font-family:Times New Roman;
	font-weight:bold;}	
p.texthdr, li.texthdr, div.texthdr
	{margin-top:12.0pt;
	margin-right:0in;
	margin-bottom:6.0pt;
	margin-left:0in;
	text-align:center;
	line-height:24.0pt;
	text-autospace:none;
	font-size:12.0pt;
	font-family:Times New Roman;
	font-weight:bold;}
p.i0, li.i0, div.i0 
	{margin-top:0in;
	margin-right:0in;
	margin-bottom:6.0pt;
	margin-left:0in;
	font-size:13.0pt;
	font-family:Garamond;
	font-weight:bold;}
p.i10, li.i10, div.i10
	{margin-top:0in;
	margin-right:0in;
	margin-bottom:0in;
	margin-left:1.0in;
	margin-bottom:.0001pt;
	text-align:justify;
	line-height:24.0pt;
	text-autospace:none;
	font-size:12.0pt;
	font-family:Times New Roman;}
p.i15, li.i15, div.i15
	{margin-top:9.0pt;
	margin-right:0in;
	margin-bottom:0in;
	margin-left:1.25in;
	margin-bottom:.0001pt;
	text-align:justify;
	text-autospace:none;
	font-size:12.0pt;
	font-family:Times New Roman;}
p.i20, li.i20, div.i20
	{margin-top:0in;
	margin-right:0in;
	margin-bottom:0in;
	margin-left:1.5in;
	margin-bottom:.0001pt;
	text-align:justify;
	//text-indent:.5in;
	line-height:24.0pt;
	text-autospace:none;
	font-size:12.0pt;
	font-family:Times New Roman;}
div.table tr td
{vertical-align:top;margin-top:0in;}
p.tdv, div.tdv, td.tdv
{vertical-align:top;margin-top:0in;}
@page Section1
	{size:8.5in 11.0in;
	margin:1.0in 1.25in 1.0in 1.25in;
	gutter:0.12in;}
div.Section1
	{page:Section1;}
@page Section2
	{size:8.5in 11.0in;
	margin:.85in .75in .25in .75in;
		gutter:0.12in;}
div.Section2
	{page:Section2;}	

p.bilnum, li.bilnum, div.bilnum, p.billno, li.billno, div.billno {
	mso-style-name:bilnum;
	margin:0in;
	margin-bottom:.0001pt;
	text-align:justify;
	line-height:24.0pt;
	text-autospace:none;
	font-size:12.0pt;
	font-family:"Arial","sans-serif";
}

p.enactstm, li.enactstm, div.enactstm {
	margin:0in; 
	margin-bottom:.0001pt; 
	text-align:justify; 
	text-indent:.5in; 
	line-height:24.0pt; 
	font-size:12.0pt; 
	font-family:"Times New Roman"; 
	font-weight:bold;

	.bracket {
		font-style: normal;
		font-weight: normal;
		text-decoration: none;
	}
}

p.end, li.end, div.end {
	margin:0in;
	margin-bottom:.0001pt;
	text-align:center;
	line-height:24.0pt;
	font-size:12.0pt;
	font-family:"Times New Roman";
}

p.i0, li.i0, div.i0 {
	margin-top:0in;
	margin-right:0in;
	margin-bottom:6.0pt;
	margin-left:0in;
	font-size:13.0pt;
	font-family:Garamond;
	font-weight:bold;
}

p.textind, li.textind, div.textind {
	margin:0in;
	margin-bottom:.0001pt;
	text-align:justify;
	text-indent:.5in;
	line-height:24.0pt;
	font-size:12.0pt;
	font-family:"Times New Roman";
}

p.textbl, li.textbl, div.textbl {
	margin:0in;
	margin-bottom:.0001pt;
	text-align:justify;
	line-height:24.0pt;
	font-size:12.0pt;
	font-family:"Times New Roman";
}

p.textbi, li.textbi, div.textbi {
	margin-top:0in;
	margin-right:0in;
	margin-bottom:0in;
	margin-left:.5in;
	margin-bottom:.0001pt;
	text-align:justify;
	line-height:24.0pt;
	font-size:12.0pt;
	font-family:"Times New Roman";
}

p.textasis, li.textasis, div.textasis {
	margin:0in;
	margin-bottom:.0001pt;
	font-size:11.0pt;
	font-family:Courier;
	color:black;
}

p.table, li.table, div.table {
	margin:0in;
	margin-bottom:.0001pt;
	font-size:11.0pt;
	font-family:Courier;
	color:black;
}

p.testc, li.testc, div.testc {
	margin:0in;
	margin-bottom:.0001pt;
	text-align:center;
	line-height:24.0pt;
	font-size:12.0pt;
	font-family:Courier;
}

p.tablec, li.tablec, div.tablec {
	margin:0in;
	margin-bottom:.0001pt;
	text-align:center;
	line-height:24.0pt;
	font-size:12.0pt;
	font-family:Courier;
}

p.tblhdn, li.tblhdn, div.tblhdn {
	margin:0in;
	margin-bottom:.0001pt;
	valign:bottom;
	text-align:left;
	margin-right:3.0pt;
	line-height:24.0pt;
	font-size:12.0pt;
	font-family:"Times New Roman";
}

p.tbllft, li.tbllft, div.tbllft {
	margin:0in;
	margin-bottom:.0001pt;
	text-align:left;
	line-height:24.0pt;
	font-size:12.0pt;
	font-family:"Times New Roman";
}

p.tblctr, li.tblctr, div.tblctr {
	margin:0in;
	margin-bottom:.0001pt;
	text-align:center;
	line-height:24.0pt;
	font-size:12.0pt;
	font-family:"Times New Roman";
}

p.tblrt, li.tblctr, div.tblctr {
	margin:0in;
	margin-bottom:.0001pt;
	text-align:right;
	line-height:24.0pt;
	font-size:12.0pt;
	font-family:"Times New Roman";
}

p.textctr, li.textctr, div.textctr {
	margin:0in;
	margin-bottom:.0001pt;
	text-align:center;
	line-height:24.0pt;
	font-size:12.0pt;
	font-family:"Times New Roman";
}
p.patronLine, li.patronLine, div.patronLine {
	margin: 0in;
	margin-bottom: .0001pt;
	text-align: center;
	line-height: 24.0pt;
	font-size: 12.0pt;
	font-family: "Times New Roman";
}
p.drafter, li.drafter, div.drafter {
	margin:0in;
	margin-bottom:.0001pt;
	line-height:24.0pt;
	font-size:12.0pt;
	font-family:"Times New Roman";
	font-weight:bold;
}

p.bilblank, li.bilblank, div.bilblank {
	margin-top:12.0pt;
	margin-right:0in;
	margin-bottom:0in;
	margin-left:0in;
	margin-bottom:.0001pt;
	text-align:center;
	line-height:24.0pt;
	font-size:12.0pt;
	font-family:"Times New Roman";
	font-weight:bold;
}

p.pro, li.pro, div.pro {
	margin:0in;
	margin-bottom:.0001pt;
	text-align:center;
	line-height:24.0pt;
	font-size:12.0pt;
	font-family:"Times New Roman";
}

p.section, li.section, div.section {
	margin:0in;
	margin-bottom:.0001pt;
	text-align:justify;
	text-indent:.5in;
	line-height:24.0pt;
	font-size:12.0pt;
	font-family:"Times New Roman";
	color:black;
}

p.sumtext:not(div.bill-details-summary p.sumtext), li.sumtext, div.sumtext {
	margin:0in;
	margin-bottom:.0001pt;
	text-align:justify;
	text-indent:.5in;
	text-autospace:none;
	font-size:12.0pt;
	font-family:"Times New Roman";
}

p.lddata, li.lddata, div.lddata {
	margin:0in;
	margin-bottom:.0001pt;
	font-size:10.0pt;
	font-family:"Times New Roman";
}

p.ld, li.ld, div.ld {
	margin:0in;
	margin-bottom:.0001pt;
	line-height:24.0pt;
	font-size:12.0pt;
	font-family:"Times New Roman";
}

p.patctr, li.patctr, div.patctr {
	margin:0in;
	margin-bottom:.0001pt;
	text-align:center;
	line-height:24.0pt;
	font-size:12.0pt;
	font-family:"Times New Roman";
}

p.ldtitle, li.ldtitle, div.ldtitle {
	margin-top:0in;
	margin-right:0in;
	margin-bottom:12.0pt;
	margin-left:.5in;
	text-align:justify;
	text-indent:-.5in;
	line-height:24.0pt;
	font-size:12.0pt;
	font-family:"Times New Roman";

	.bracket {
		font-style: normal;
		font-weight: normal;
		text-decoration: none;
	}
}

p.patalt, li.patalt, div.patalt {
	margin-top:12.0pt;
	margin-right:0in;
	margin-bottom:12.0pt;
	margin-left:.5in;
	text-align:justify;
	text-indent:-.5in;
	line-height:24.0pt;
	font-size:12.0pt;
	font-family:"Times New Roman";
	font-weight:bold;
}

p.textrt, li.textrt, div.textrt {
	margin:0in;
	margin-bottom:.0001pt;
	text-align:right;
	text-indent:.5in;
	line-height:24.0pt;
	font-size:12.0pt;
	font-family:"Times New Roman";
	color:black;
}

p.texthdrchar {
	margin-top:12.0pt;
	margin-right:0in;
	margin-bottom:6.0pt;
	margin-left:0in;
	text-align:center;
	line-height:24.0pt;
	text-autospace:none;
	font-size:12.0pt;
	font-family:Times New Roman;
	font-weight:bold;
}

p.texthdr, li.texthdr, div.texthdr {
	margin-top:12.0pt;
	margin-right:0in;
	margin-bottom:6.0pt;
	margin-left:0in;
	text-align:center;
	line-height:24.0pt;
	text-autospace:none;
	font-size:12.0pt;
	font-family:Times New Roman;
	font-weight:bold;
}

p.new, li.new, div.new {	
	background-color: yellow;
}