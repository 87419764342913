@media screen and (max-width: 900px) {
  .full {
    width: 100%;
    max-width: 100%; }
  .mobile-newline {
    opacity: 0;
    display: block;
    height: 1px; }
  .icon {
    width: 32px;
    height: 32px;
    background-size: 32px 32px;
    vertical-align: middle; }
  li .icon.save, li .icon.delete {
    width: 16px;
    height: 16px;
    background-size: 16px 16px; }
  .button.small-button {
    padding: 4px 10px; }
  .button-link {
    padding: 4px 10px;
    font-family: 'Lato', sans-serif;
    font-weight: 400;
    color: #34495e;
    font-size: 13px;
    cursor: pointer;
    background: #3498db;
    display: inline-block;
    border: none;
    white-space: nowrap;
    border-radius: 4px;
    color: #FFFFFF;
    font-weight: 600;
    text-decoration: none;
    vertical-align: middle; }
  .overlay {
    background-color: white; }
    .overlay.center-content {
      display: block; }
  .popup-inner {
    left: 10px;
    right: 10px;
    top: 10%;
    bottom: auto; }
  #menu-slideout {
    display: block; }
  .menu-links {
    margin: 0;
    margin-top: 5px;
    padding: 0px; }
    .menu-links .menu-btn {
      margin: 10px 0px; }
  .menu-title {
    padding: 0px;
    margin: 0px;
    margin-top: 5px;
    height: 50px; }
    .menu-title a {
      background-size: contain; }
  #site-title-container {
    height: auto; }
    #site-title-container .site-title-content {
      display: block; }
      #site-title-container .site-title-content h1 {
        font-size: 1em;
        text-align: center;
        padding: 10px 0px 5px 0px; }
      #site-title-container .site-title-content .global-search-box {
        padding-bottom: 10px; }
        #site-title-container .site-title-content .global-search-box form {
          align-items: unset;
          margin-right: 0px; }
        #site-title-container .site-title-content .global-search-box input {
          height: 40px; }
        #site-title-container .site-title-content .global-search-box .input-searchbtn {
          width: 40px; }
  #session-select-container {
    padding-bottom: 20px;
    grid-template-rows: 1fr 1fr;
    grid-template-columns: 1fr; }
    #session-select-container .session-select-tab {
      margin: 0 auto;
      width: 80%; }
      #session-select-container .session-select-tab #sessionSelector .global-session-selector__single-value {
        left: 50%;
        transform: translate(-50%, -50%); }
    #session-select-container .link-wrapper {
      align-content: center;
      margin: 10px 10%;
      border-radius: 5px;
      text-align: center; }
  .footer {
    height: 220px;
    background-position: bottom;
    background-size: 100px; }
  .footer-wrapper {
    position: relative;
    margin-top: 10px; }
  .full.homepage-wrapper {
    width: unset; }
  .home-search-section br, .home-search-section hr {
    display: none; }
  .home-search-section #search-selectors {
    grid-template-columns: 1fr;
    margin-bottom: 0px; }
    .home-search-section #search-selectors .selector, .home-search-section #search-selectors .selector:first-of-type, .home-search-section #search-selectors .selector:last-of-type {
      display: flex;
      align-items: center;
      margin: 5px 0px;
      white-space: nowrap;
      border-bottom: 1px solid #ddd;
      padding: 0px 10px 10px 10px; }
    .home-search-section #search-selectors .selector > div:last-of-type {
      width: 90%; }
    .home-search-section #search-selectors #bills-by-date {
      height: 3em; }
  .session-info {
    grid-template-columns: 1fr; }
    .session-info h3 {
      text-align: center; }
    .session-info .homepage-links {
      padding: 0px 10px; }
    .session-info a {
      line-height: 2em; }
  .full .grid-wrapper .center-column {
    margin: 0px; }
  .homepage-schedule-item {
    padding: 0px 10px; }
  .message-of-the-day {
    margin: 0px auto 10px auto; }
    .message-of-the-day .message-list .message-item {
      min-width: 85%;
      max-width: 100%; }
  .public-chamber-data h2, .public-chamber-data h3 {
    text-align: center; }
  .public-chamber-data h2 {
    margin: 0px 0px; }
  .public-chamber-data h3 {
    margin-top: 10px; }
  .public-chamber-data .spinner {
    margin: 0 auto; }
  .public-chamber-data #chamber-vote-leg-links {
    margin: 0px 10px; }
  .public-chamber-data .list-desc a {
    padding-left: 5px;
    line-height: 2em; }
  #legislator-search-title {
    text-align: center; }
  .location-search-form {
    margin: 0px 10px;
    display: grid;
    grid-template-columns: 1fr 0.35fr; }
    .location-search-form .location-search-input {
      width: 100%; }
  .generic-details-page .print {
    margin-right: 10px; }
  .generic-details-page h2 {
    padding-left: 10px;
    padding-right: 10px;
    text-align: center;
    margin-top: 0px; }
  .generic-details-page h3 {
    padding-left: 10px;
    padding-right: 10px; }
  .generic-details-page .details-section {
    padding-left: 10px;
    padding-right: 10px; }
  .generic-details-page .details-header {
    display: block; }
  .generic-details-page .description {
    padding-left: 10px;
    padding-right: 10px; }
  .generic-details-page .details-page-data-container {
    padding-left: 10px;
    padding-right: 10px; }
  .generic-details-page.minutes-details-page > .flex-row {
    display: block;
    position: relative; }
    .generic-details-page.minutes-details-page > .flex-row h2 {
      padding-top: 32px; }
    .generic-details-page.minutes-details-page > .flex-row .entry-text {
      padding-left: 10px;
      padding-right: 10px; }
    .generic-details-page.minutes-details-page > .flex-row .print {
      position: absolute;
      right: 10px;
      top: 0; }
  .generic-details-page.committee-details-page tr {
    display: block;
    padding-left: 10px;
    padding-right: 10px; }
  .generic-details-page.committee-details-page .button-container {
    margin-left: 10px; }
  .generic-details-page.meetings-details-page h4 {
    padding-left: 10px;
    padding-right: 10px; }
  .generic-details-page.meetings-details-page .inner-grid.two-col-sidebar-backwards {
    display: block;
    padding-left: 10px;
    padding-right: 10px; }
  .generic-details-page.docket-details-page .list-desc, .generic-details-page.docket-details-page h4 {
    padding-left: 10px;
    padding-right: 10px; }
  .generic-details-page.calendar-details-page > div br:first-child {
    display: none; }
  .generic-details-page.calendar-details-page .multiple-icons {
    position: relative;
    display: block; }
    .generic-details-page.calendar-details-page .multiple-icons h2 {
      padding-top: 40px; }
    .generic-details-page.calendar-details-page .multiple-icons a {
      position: absolute;
      top: 0px;
      right: 10px; }
      .generic-details-page.calendar-details-page .multiple-icons a .icon {
        width: 24px;
        height: 24px;
        background-size: 24px 24px; }
    .generic-details-page.calendar-details-page .multiple-icons > a:last-child {
      right: 40px; }
  .generic-details-page.calendar-details-page h3 {
    padding: 0px; }
  .generic-details-page.calendar-details-page .section-header {
    display: grid;
    grid-template-columns: 8fr 1fr; }
    .generic-details-page.calendar-details-page .section-header button {
      margin-bottom: 3px; }
    .generic-details-page.calendar-details-page .section-header span {
      display: block; }
  .generic-details-page.calendar-details-page .section-row {
    display: block; }
    .generic-details-page.calendar-details-page .section-row .align-right {
      text-align: left;
      display: flex;
      align-items: center; }
    .generic-details-page.calendar-details-page .section-row a, .generic-details-page.calendar-details-page .section-row span {
      line-height: 2em; }
  .generic-details-page.calendar-details-page .med-two-one {
    display: block; }
  .generic-details-page.calendar-details-page .bill-details-versions-grid {
    display: block; }
  .generic-details-page.calendar-details-page .section-row:nth-child(even) {
    background-color: rgba(0, 0, 0, 0.03); }
  .note-wrapper {
    padding: 0px 10px; }
  .note-button {
    margin-left: 10px; }
  .statistics-table {
    display: block; }
    .statistics-table caption {
      width: 100%;
      display: block; }
      .statistics-table caption .statistics-header {
        display: block; }
      .statistics-table caption h1, .statistics-table caption a {
        padding: 0px 10px;
        margin: 0; }
      .statistics-table caption a {
        display: block; }
      .statistics-table caption span {
        display: none; }
    .statistics-table tbody {
      display: block;
      overflow-x: scroll; }
  #search-selections-header {
    margin: 10px;
    text-align: center; }
  .bill-search-container {
    width: 90%;
    margin: 0 auto; }
    .bill-search-container .dlas-forms.side-form.advanced-search {
      margin: -10px auto 0; }
  .lis-search-categories .dlas-forms.inner-grid.four {
    margin: 10px 0; }
    .lis-search-categories .dlas-forms.inner-grid.four .react-datepicker-wrapper #sr-introduced {
      width: 100%; }
  .lis-search-categories .homepage-links {
    margin: 10px 0;
    line-height: 2em; }
  .collection-controls {
    margin: 10px 0 0;
    display: block; }
    .collection-controls h4 {
      padding-bottom: 0px; }
    .collection-controls .inline-list > * {
      margin-right: 0;
      margin-left: 0; }
    .collection-controls .inline-list br {
      height: 0.5em; }
    .collection-controls .watchlist-info .icon {
      margin-left: 10px; }
    .collection-controls .close-or-delete-watchlist {
      padding-top: 20px;
      display: flex;
      justify-content: space-between; }
      .collection-controls .close-or-delete-watchlist br {
        display: none; }
      .collection-controls .close-or-delete-watchlist button {
        padding: 5px 10px; }
    .collection-controls .save-or-delete .icon {
      margin-right: 32px;
      margin-bottom: 32px;
      margin-top: 16px; }
    .collection-controls .edit-watchlist-name {
      display: block; }
    .collection-controls .edit.icon {
      width: 18px;
      height: 18px;
      background-size: 18px 18px; }
  .info-box.notification-tooltip.all-legislation {
    margin-left: unset; }
    .info-box.notification-tooltip.all-legislation:before {
      left: 80px; }
  .search-selections .txt-greyed.small-text {
    margin: 10px; }
  .search-results-title-section {
    display: block;
    margin: 10px 0; }
    .search-results-title-section h2 {
      margin-bottom: 5px; }
    .search-results-title-section button, .search-results-title-section span {
      margin-top: 5px;
      margin-bottom: 5px;
      margin-left: 0px; }
    .search-results-title-section input {
      margin-left: 0px; }
    .search-results-title-section .inline-list > * {
      display: inline-block; }
  #sr-select-all {
    margin-left: 0px; }
  .search-results .bill-info .se-bill-search-result .toggle-row {
    position: relative; }
    .search-results .bill-info .se-bill-search-result .toggle-row .bill-result-link {
      top: 0; }
      .search-results .bill-info .se-bill-search-result .toggle-row .bill-result-link .bubble-hollow, .search-results .bill-info .se-bill-search-result .toggle-row .bill-result-link .bubble {
        width: 1.3em;
        height: 1.3em;
        background-size: 1.3em 1.3em; }
      .search-results .bill-info .se-bill-search-result .toggle-row .bill-result-link a, .search-results .bill-info .se-bill-search-result .toggle-row .bill-result-link p {
        margin-left: 0; }
      .search-results .bill-info .se-bill-search-result .toggle-row .bill-result-link .margin-left {
        margin-left: 23px; }
    .search-results .bill-info .se-bill-search-result .toggle-row .button-container {
      position: absolute;
      top: 0;
      right: 0;
      padding-right: 0 !important;
      max-width: 70%;
      display: flex;
      align-items: center; }
      .search-results .bill-info .se-bill-search-result .toggle-row .button-container span {
        white-space: normal;
        margin: 0; }
    .search-results .bill-info .se-bill-search-result .toggle-row span {
      margin-top: 10px;
      margin-bottom: 10px;
      grid-column-start: 1;
      grid-column-end: 3;
      align-self: end; }
  .search-results .note-area-container {
    margin: 10px; }
    .search-results .note-area-container .inline-list {
      margin: auto; }
    .search-results .note-area-container .icon.save {
      margin-left: 10px; }
    .search-results .note-area-container .icon.delete {
      display: none; }
  .collection-buttons input {
    margin-left: 0px;
    margin-right: 10px; }
  #create-report-button.set-right {
    float: right;
    margin-right: 0; }
  .notes-and-controls {
    margin: 10px 0;
    margin-bottom: 5px; }
    .notes-and-controls .quick-add-container {
      text-align: right;
      margin-right: 0px;
      display: inline-block; }
      .notes-and-controls .quick-add-container .small-text.fail {
        display: block; }
      .notes-and-controls .quick-add-container #bill-quick-add {
        display: inline-block;
        width: 60%; }
    .notes-and-controls .toggle-all-notes {
      margin: 0px; }
  .selection-bubble .icon {
    width: 16px;
    height: 16px;
    background-size: 16px 16px; }
  .content-blocker .expander {
    bottom: -2px; }
  .report-maker .close-cross {
    float: none;
    text-align: right; }
  .report-maker .checkbox {
    line-height: 2em; }
  .report-maker input[type=checkbox] {
    margin-right: 4px !important;
    vertical-align: middle !important; }
  .report-maker input[type=radio] {
    vertical-align: text-bottom !important; }
  .report-maker #report-history-type-recent-amount {
    width: 50px;
    height: 30px; }
  .report-maker .button.secondary {
    float: left; }
  #report-bill-list {
    display: none; }
  .report-container {
    margin: 10px; }
  .notification-section .inline-list span {
    margin-right: 0px; }
  .notification-section .inner-grid.two {
    row-gap: 15px; }
  .notification-section .icon.save, .notification-section .icon.delete {
    width: 18px;
    height: 18px;
    background-size: 18px 18px; }
  .notification-section input[type=text] {
    width: 80%; }
  .keyword-hit-list {
    display: block;
    line-height: 2em; }
  .bill-history-button {
    margin-top: 20px; }
  .bill-search-bill-history .bill-details-table-row:nth-child(odd) {
    background-color: rgba(0, 0, 0, 0.03); }
  .bill-search-bill-history .bill-details-table-row td {
    display: block;
    padding: 7px 0px;
    margin-left: 10px;
    margin-right: 10px; }
  .bill-search-bill-history .bill-details-table-row .button-container {
    text-align: left !important; }
  #bill-details .bill-description-editor {
    display: block; }
    #bill-details .bill-description-editor .icon:last-child {
      margin-left: 20px; }
  #bill-details h2, #bill-details h4 {
    padding-left: 10px;
    padding-right: 10px; }
  #bill-details .bill-details-header {
    position: relative;
    padding-top: 32px;
    display: block; }
    #bill-details .bill-details-header .button.print {
      position: absolute;
      top: 0;
      left: 0; }
    #bill-details .bill-details-header .list-links {
      padding: 0; }
  #bill-details .bill-details-header, #bill-details .list-desc {
    margin-left: 10px;
    margin-right: 10px; }
  #bill-details .description h4 {
    padding-left: 0;
    padding-right: 0; }
  #bill-details .parent-li {
    margin-left: 10px;
    margin-right: 10px; }
  #bill-details .bill-details-versions-grid-container {
    padding: 10px; }
    #bill-details .bill-details-versions-grid-container .bill-details-versions-grid {
      display: block;
      position: relative; }
      #bill-details .bill-details-versions-grid-container .bill-details-versions-grid .arrow-down, #bill-details .bill-details-versions-grid-container .bill-details-versions-grid .arrow-up {
        position: absolute;
        top: 1em;
        right: 10px; }
      #bill-details .bill-details-versions-grid-container .bill-details-versions-grid a {
        line-height: 2em; }
      #bill-details .bill-details-versions-grid-container .bill-details-versions-grid .link-pdf {
        background-image: none;
        padding-left: 0; }
    #bill-details .bill-details-versions-grid-container .impact-statements-grid {
      display: grid;
      grid-template-columns: 0px 2fr; }
  #bill-details .amendment-text {
    padding: 10px; }
  #bill-details .bill-details-versions-grid-container:nth-child(odd) {
    background-color: rgba(0, 0, 0, 0.03); }
  #bill-details .bill-details-table-row:nth-child(odd) {
    background-color: rgba(0, 0, 0, 0.03); }
  #bill-details .bill-details-table-row td {
    display: block;
    padding: 2px 0px;
    margin-left: 10px;
    margin-right: 10px; }
  #bill-details .bill-details-table-row .button-container {
    text-align: left !important; }
  #bill-details .button-link {
    margin: 10px; }
  #bill-details .patrons-list {
    display: block; }
    #bill-details .patrons-list ul {
      column-count: 1; }
  #vote-details .details-header {
    position: relative;
    display: block; }
    #vote-details .details-header h3 {
      margin-top: 40px; }
    #vote-details .details-header .list-links {
      width: 100%;
      display: flex !important;
      position: absolute;
      top: 0;
      padding: 0; }
      #vote-details .details-header .list-links.justify-left {
        flex-direction: row-reverse;
        justify-content: left !important; }
      #vote-details .details-header .list-links.justify-center {
        justify-content: center !important; }
  #vote-search .header-half {
    grid-template-columns: 1fr; }
  #vote-search .button-link {
    margin-bottom: 10px; }
  #vote-search .date-column-header {
    display: none; }
  #vote-search .vote-search-date-item {
    grid-template-columns: 1fr; }
  #vote-search .two-col-sidebar-backwards {
    grid-template-columns: 1fr; }
  #vote-search .vote-search-grid-header {
    grid-template-columns: 1fr 1fr .2fr .6fr 1.7fr 1fr;
    grid-column-gap: 5px; }
  #vote-search .vote-search-grid {
    grid-template-columns: .85fr 1fr .2fr .6fr 1.7fr 1fr;
    grid-column-gap: 5px; }
  #schedule-page {
    margin-top: -15px; }
    #schedule-page .section-side-padding {
      padding: 0 10px; }
    #schedule-page .icon {
      width: 20px;
      height: 20px;
      background-size: 20px 20px; }
    #schedule-page .header-half {
      margin: 0 10px;
      display: block; }
      #schedule-page .header-half > div {
        padding-top: 2px;
        padding-bottom: 20px; }
      #schedule-page .header-half .button-link {
        float: left; }
      #schedule-page .header-half .meeting-actions {
        padding-top: 0px; }
    #schedule-page .side-form {
      width: auto !important;
      margin: 10px; }
    #schedule-page .day-wrapper {
      border: 1px solid #e4e4e4; }
      #schedule-page .day-wrapper .button-right, #schedule-page .day-wrapper .button-left {
        margin-top: 0;
        margin-left: 10px;
        margin-right: 10px; }
      #schedule-page .day-wrapper .mobile-left {
        text-align: left !important;
        padding-left: 20px; }
    #schedule-page .schedule-content-wrapper {
      width: auto !important; }
    #schedule-page .schedule-event {
      min-height: 70px !important; }
      #schedule-page .schedule-event .schedule-event-grid {
        grid-template-columns: 8% 75% 17%; }
      #schedule-page .schedule-event .schedule-checkbox-and-content {
        position: relative; }
    #schedule-page .public-schedule-event {
      min-height: 70px !important; }
    #schedule-page .schedule-management-controls {
      margin-bottom: 5px; }
      #schedule-page .schedule-management-controls .icon {
        width: 15px;
        height: 15px;
        background-size: 15px 15px; }
    #schedule-page .two-col-sidebar-backwards-alt-2 {
      grid-template-columns: 20% 80%; }
    #schedule-page .two-col-sidebar-tiny-backwards {
      grid-template-columns: 25px 1fr; }
    #schedule-page .header-half {
      border: 0; }
  .dlas-forms.change-password label, .dlas-forms.create-account label {
    margin-bottom: 0px;
    display: inline-block;
    width: fit-content; }
  .mobile-w-100 {
    width: 100%; }
  .mobile-mb-10 {
    margin-bottom: 10px; }
  .composite-view-header {
    grid-template-columns: 1fr 1fr 1fr .6fr;
    grid-gap: 0;
    text-wrap: nowrap; }
  .composite-view-wrapper {
    height: 240px;
    font-size: 12px;
    grid-template-rows: 165px 50px; }
    .composite-view-wrapper.notes-hidden {
      height: 175px; }
    .composite-view-wrapper .composite-view-data {
      grid-gap: 15px;
      grid-template-columns: 1fr 1fr 1fr .7fr;
      overflow: hidden;
      text-overflow: ellipsis; }
      .composite-view-wrapper .composite-view-data .composite-view-committees {
        grid-template-rows: 80px 80px; } }
